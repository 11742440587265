import React from "react";
import SEO from "../components/SEO";
import { theme } from "@src/components/nessie-web";

import ScrollCTA from "@src/components/partials/engineering/ScrollCTA";
import HeroSection from "@src/components/partials/engineering/HeroSection";
import CoverageSection from "@src/components/partials/press/CoverageSection";
import WorkAnywhereSection from "@src/components/partials/engineering/WorkAnywhereSection";
import BrandLoveSection from "@src/components/partials/about/BrandLoveSection";
import VideoSection from "@src/components/partials/engineering/VideoSection";
import OurValuesSection from "@src/components/partials/engineering/OurValuesSection";
import TeamSection from "@src/components/partials/about/TeamSection";
import OpenPositions from "@src/components/partials/engineering/OpenPositions";
import BlogSection from "@src/components/partials/engineering/BlogSection";
import ExtendedFooter from "@src/components/partials/engineering/ExtendedFooter";
import FAQSection from "@src/components/partials/engineering/FAQSection";
import { graphql, useStaticQuery } from "gatsby";
import { defaultNormalizeStaticQueryResult } from "@src/utils/normalize-static-query-result";

const {
  colors: { dt_taro10 },
} = theme;

const EngineeringPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      directus {
        page_engineering {
          page_title
          page_description
          carousel_items
          faq_items
          carousel_space_image {
            id
            filename_disk
          }
          dojo_islands_video
          work_anywhere_animation
          press_logo {
            id
            filename_disk
          }
          press_button_url
          hero_image {
            id
            filename_disk
          }
          team_title
          team_item_image_1 {
            id
            filename_disk
          }
          blog_posts
          values_items
          values_heading
          values_sub_heading
          team_item_username_1
          team_item_role_1
          team_item_text_1
          team_item_image_2 {
            id
            filename_disk
          }
          team_item_username_2
          team_item_role_2
          team_item_text_2
          team_item_image_3 {
            id
            filename_disk
          }
          team_item_username_3
          team_item_role_3
          team_item_text_3
          footer_twitter_button_link
          footer_press_release_button_link
        }
        page_careers {
          brand_love_images
          brand_love_tweets
        }
      }
    }
  `);

  defaultNormalizeStaticQueryResult(data);

  const {
    directus: {
      page_engineering: {
        page_title,
        page_description,
        carousel_items,
        faq_items,
        values_items,
        work_anywhere_animation,
        dojo_islands_video,
        carousel_space_image,
        press_logo,
        hero_image,
        press_button_url,
        team_title,
        team_item_image_1,
        team_item_username_1,
        team_item_role_1,
        team_item_text_1,
        team_item_image_2,
        team_item_username_2,
        team_item_role_2,
        team_item_text_2,
        team_item_image_3,
        team_item_username_3,
        team_item_role_3,
        team_item_text_3,
        footer_twitter_button_link,
        footer_press_release_button_link,
        blog_posts,
      },
      page_careers: { brand_love_images, brand_love_tweets },
    },
  } = data;

  const ScrollCTAProps = {
    button_text: "directus.page_engineering.fixed_cta_button_text",
    button_scroll_location: "#openings",
  };

  const HeroSectionProps = {
    hero_text: "directus.page_engineering.hero_text",
    hero_button_text: "directus.page_engineering.hero_button_text",
    hero_image: hero_image,
    press_button_text: "directus.page_engineering.press_button_text",
    press_heading: "directus.page_engineering.press_heading",
    press_sub_heading: "directus.page_engineering.press_sub_heading",
    press_logo,
    press_button_url,
    button_scroll_location: "#openings",
  };

  const CarouselSectionProps = {
    coverage_space_image: carousel_space_image,
    coverage_items_data: carousel_items.map((item, idx) => ({
      text: `directus.page_engineering.carousel_items.text_${idx + 1}`,
    })),
  };

  const WorkAnywhereSectionProps = {
    work_anywhere_heading: "directus.page_engineering.work_anywhere_heading",
    work_anywhere_subheading: "directus.page_engineering.work_anywhere_subheading",
    work_anywhere_text: "directus.page_engineering.work_anywhere_text",
    work_anywhere_animation,
  };

  const VideoSectionProps = {
    video_section_heading: "directus.page_engineering.dojo_islands_text",
    video_section_url: dojo_islands_video,
  };

  const OurValuesSectionProps = {
    values_heading: "directus.page_engineering.values_heading",
    values_sub_heading: "directus.page_engineering.values_sub_heading",
    values_items,
  };

  const TeamSectionProps = {
    team_title,
    team_item_image_1,
    team_item_username_1,
    team_item_role_1,
    team_item_text_1,
    team_item_image_2,
    team_item_username_2,
    team_item_role_2,
    team_item_text_2,
    team_item_image_3,
    team_item_username_3,
    team_item_role_3,
    team_item_text_3,
    title_align_center: true,
  };

  const BrandLoveSectionProps = {
    brand_love_title: "directus.page_engineering.brand_love_title",
    brand_love_text: "directus.page_engineering.brand_love_text",
    brand_love_cta_text: "directus.page_careers.brand_love_cta_text",
    brand_love_images,
    brand_love_tweets,
    BGColor: false,
    three_liner: false,
    translate: true,
  };

  const FAQSectionProps = {
    faq_title: "directus.page_engineering.faq_heading",
    faq_items,
    translationPath: "directus.page_engineering.",
  };

  const BlogSectionProps = {
    news_title: "directus.page_engineering.blog_text",
    see_all_button_text: "directus.page_engineering.blog_see_all_button_text",
    blog_posts,
  };

  const OpenPositionsProps = {
    open_positions_heading: "directus.page_engineering.open_positions_heading",
    open_positions_engineering: "directus.page_engineering.open_positions_engineering",
  };

  const ExtendedFooterProps = {
    headingText: "directus.page_engineering.footer_heading",
    learnMoreText: "directus.page_engineering.footer_learnmore",
    followUsText: "directus.page_engineering.footer_followus",
    pressReleaseButtonText: "directus.page_engineering.footer_press_release_button_text",
    pressReleaseButtonLink: footer_press_release_button_link,
    twitterButtonLink: footer_twitter_button_link,
  };

  return (
    <>
      <SEO title={page_title} description={page_description} />
      <ScrollCTA {...ScrollCTAProps} />
      <HeroSection {...HeroSectionProps} />
      <CoverageSection {...CarouselSectionProps} />
      <WorkAnywhereSection {...WorkAnywhereSectionProps} />
      <VideoSection {...VideoSectionProps} />
      <OurValuesSection {...OurValuesSectionProps} />
      <div style={{ backgroundColor: dt_taro10 }}>
        <TeamSection {...TeamSectionProps} />
      </div>
      <BrandLoveSection {...BrandLoveSectionProps} />
      <FAQSection {...FAQSectionProps} />
      <BlogSection {...BlogSectionProps} />
      <OpenPositions {...OpenPositionsProps} />
      <ExtendedFooter {...ExtendedFooterProps} />
    </>
  );
};
export default EngineeringPage;
